<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-form', params: { accountId: this.$route.params.accountId } }">Forms</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ form.label }}</h1>
                <p class="text-caption text-center">Form overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="form">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption grey--text text-end my-0">{{ typeDisplay }} {{ form.id }}</p>

                    <v-card>
                        <v-app-bar color="purple darken-2" dense dark elevation="2">
                            <v-app-bar-title>
                                Form
                            </v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>

                            <p class="text-overline mb-0 mt-2">Title</p>
                            <p class="mb-0 pb-0">
                                {{ form.title }}
                                <v-btn icon color="purple" @click="editFormTitle" class="no-print">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Form ID</p>
                            <p class="mb-0 pb-0">
                                {{ form.id }}
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Type</p>
                            <p class="mb-0 pb-0">
                                {{ typeDisplay }}
                            </p> -->

                            <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0">
                                <span v-if="isPublished">
                                    <a :href="invite.link" v-if="invite && invite.link" target="_blank">Published</a>
                                    <!-- <font-awesome-icon :icon="['fas', 'external-link-alt']" class="ml-2" style="font-size: 12px;"/> -->
                                </span>
                                <span v-if="!isPublished">
                                Draft
                                </span>
                                <v-btn icon color="purple" @click="editFormStatus" class="no-print">
                                    <font-awesome-icon :icon="['fas', 'cog']"/>
                                </v-btn>
                            </p>
                            <p class="mb-0 pb-0 no-print" v-if="isPublished">
                                <router-link :to="{ name: 'account-view-form-data', params: { accountId: this.$route.params.accountId, formId: this.$route.params.formId } }">View data</router-link>
                            </p>

                            <!-- TODO: display type: long form (1 page) or paginated (max n questions per page), and also user can insert page additional breaks as needed -->

                            <!-- TODO: button to add an item to the sequence -->
                            <p class="text-overline mb-0 mt-8">Sequence <v-btn icon color="purple" @click="addInput" class="no-print"><font-awesome-icon :icon="['fas', 'plus']"/></v-btn></p>
                            <p class="mb-0 pb-0" v-if="form.sequence.length === 0">
                                Tap the plus button to create the first item in the form.
                            </p>
                            <v-list v-if="form.sequence.length > 0">
                                <v-list-item v-for="(item, idx) in form.sequence" v-bind:key="idx">
                                    <template v-if="item.type === 'input'">
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            {{ form.input[item.key].label }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ form.input[item.key].type }}
                                        </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon color="purple" @click="editItem(idx)" class="no-print">
                                                <font-awesome-icon :icon="['fas', 'pencil-alt']" style="font-size: 16px;" fixed-width/>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                            <v-menu offset-y left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon color="purple" v-on="on" class="no-print">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                    </v-btn>
                                                    <!-- <v-tab v-on="on" class="purple--text">
                                                        <Avatar :attr="mainProfile" :size="36"/>
                                                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                                                    </v-tab> -->
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <v-list-item @click="deleteItem(idx)">
                                                        <!-- <v-list-item-content> -->
                                                            <v-list-item-title>Remove</v-list-item-title>
                                                        <!-- </v-list-item-content> -->
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </template>
                                    <template v-else-if="item.type === 'hr'">
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Horizontal separator
                                        </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-menu offset-y left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon color="purple" v-on="on" class="no-print">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                    </v-btn>
                                                    <!-- <v-tab v-on="on" class="purple--text">
                                                        <Avatar :attr="mainProfile" :size="36"/>
                                                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                                                    </v-tab> -->
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <v-list-item @click="deleteItem(idx)">
                                                        <!-- <v-list-item-content> -->
                                                            <v-list-item-title>Remove</v-list-item-title>
                                                        <!-- </v-list-item-content> -->
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </template>
                                    <template v-else-if="item.type === 'break'">
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Page break
                                        </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-menu offset-y left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon color="purple" v-on="on" class="no-print">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                    </v-btn>
                                                    <!-- <v-tab v-on="on" class="purple--text">
                                                        <Avatar :attr="mainProfile" :size="36"/>
                                                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                                                    </v-tab> -->
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <v-list-item @click="deleteItem(idx)">
                                                        <!-- <v-list-item-content> -->
                                                            <v-list-item-title>Remove</v-list-item-title>
                                                        <!-- </v-list-item-content> -->
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                            Unknown item type {{ JSON.stringify(item.type) }}
                                        </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-menu offset-y left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon color="purple" v-on="on" class="no-print">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                    </v-btn>
                                                    <!-- <v-tab v-on="on" class="purple--text">
                                                        <Avatar :attr="mainProfile" :size="36"/>
                                                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                                                    </v-tab> -->
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <v-list-item @click="deleteItem(idx)">
                                                        <!-- <v-list-item-content> -->
                                                            <v-list-item-title>Remove</v-list-item-title>
                                                        <!-- </v-list-item-content> -->
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list>

                            <p class="text-overline mb-0 mt-8">
                                Confirmation
                                <v-btn icon color="purple" @click="editFormConfirmation" class="no-print">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>
                            <p class="mb-0 pb-0" v-if="form.confirm">
                                <span class="font-weight-bold">{{ form.confirm.headline }}</span>
                            </p>
                            <p class="mb-0 pb-0" v-if="form.confirm">
                                {{ form.confirm.message }}
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Usage</p>
                            <p class="mb-0 pb-0">
                                {{ usage }}
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon @click="checkFormUsage" v-bind="attrs" v-on="on">
                                            <font-awesome-icon :icon="['far', 'sync-alt']"/>
                                        </v-btn>
                                    </template>
                                    Refresh
                                </v-tooltip>
                            </p> -->

                            <!-- <template v-if="form.website_id">
                            <p class="text-overline mb-0 mt-8">Website</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: form.website_id } }">
                                    <span v-if="website">{{ website.label }}</span>
                                    <span v-if="!website">Website</span>
                                </router-link>
                            </p>
                            </template> -->

                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, formId: this.$route.params.formId } }">Access control</router-link>
                            </p> -->
                        </v-card-text>
                    </v-card>

                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8 no-print" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Form Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editFormTitleDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Edit the form title</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The form title is how the form will be presented to your users.
                            It does NOT need to be unique.
                            You can change it at any time.
                            It is <span class="font-weight-bold">public</span> and WILL be shown to users.
                        </p>
                        <v-text-field v-model="editableFormTitle" label="Display title"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="saveEditFormTitle" :disabled="!isEditFormTitleComplete">
                        <span>Next</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editFormTitleDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="createNewItemDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Create new form item</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <v-select v-model="editableItemType" label="Type" :items="formItemTypeChoices"></v-select>
                        <template v-if="editableItemType === 'input'">
                            <p>The label will be shown to users and is also the column name for this input when you view or export form data.</p>
                            <v-text-field v-model="editableInputLabel" label="Label"></v-text-field>
                            <!-- <v-text-field v-model="editableInputKey" label="Key"></v-text-field> -->
                        </template>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                        <span>Next</span>
                    </v-btn>
                    <v-btn text color="grey" @click="createNewItemDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editInputDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Edit form item</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                    <v-text-field v-model="editableInputPrompt" label="Prompt" hint="The prompt appears before the input (optional)"></v-text-field>
                    <v-text-field v-model="editableInputNotice" label="Notice" hint="The notice appears between the prompt and the input (optional)"></v-text-field>
                    <v-text-field v-model="editableInputLabel" label="Label" hint="This label will be displayed to the user (required)"></v-text-field>
                    <v-text-field v-model="editableInputHelptext" label="Help text" hint="The help text appears after the input (optional)"></v-text-field>

                    <!-- TODO: keys cannot be renamed casually; there should be a separate "rename key" form for that -->
                    <!-- <v-text-field v-model="editableInputKey" label="Key" hint="The column name when viewing form data"></v-text-field>
                    <p>NOTE: the "key" isn't directly visible to users but it should be considered as public information because advanced users can see the name of the key.</p> -->
                    <v-select v-model="editableInputType" label="Type" :items="formInputTypeChoices"></v-select>

                    <v-checkbox v-model="editableInputIsRequired" dense label="Required"/> <!-- allows user to select more than one choice; field will have an array value -->

                    <!-- TODO: editing the input key is advanced; it should be autoamtically generated from label as user types, and if there's already a key like that we should automatically append a number; and only show this key editin ginput if user asks for it by opening advanced settings in the dialog -->
                    <!-- TODO: based on item type, might need more inputs here... if it's multiple choice, we need to get the list of available choices; if it's single-select, also need a list of available choices; if it's text, we can prompt for max length or min length ;  if it's a currency amount we can prompt for the currency name here and also prompt if author wants to see user's original input currency and amount, or always just convert to target currency and amount (or maybe we always do both and don't prompt here ), if it's an image upload, we can prompt here for max size or dimensions, or allowed types (png, jpg, gif, etc.) -->
                    <template v-if="editableInputType === 'select'">
                        <v-checkbox v-model="editableInputIsArray" dense label="Multi-select"/> <!-- allows user to select more than one choice; field will have an array value -->
                        <!-- TODO: array minimum entries required, default 0 -->
                        <!-- TODO: array maximum entries allowed, default null -->
                        <p class="text-overline mb-0 mt-8">Choices <v-btn icon color="purple" @click="addSelectChoice"><font-awesome-icon :icon="['fas', 'plus']"/></v-btn></p>
                        <p class="mb-0 pb-0" v-if="editableInputSelectList.length === 0">
                            Add at least one choice
                        </p>
                        <v-list v-if="editableInputSelectList.length > 0">
                            <v-list-item v-for="(item, idx) in editableInputSelectList" v-bind:key="idx">
                                <template v-if="editableInputSelectListEditIndex === idx">
                                    <v-list-item-content>
                                        <v-col>
                                        <v-text-field v-model="editableInputSelectListEditLabel" label="Display text"/>
                                        <v-checkbox v-model="editableInputSelectListEditCustomValue">Custom value</v-checkbox>
                                        <v-text-field v-if="editableInputSelectListEditCustomValue" v-model="editableInputSelectListEditValue" label="Store text"/>
                                        </v-col>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="purple" @click="saveSelectChoice(idx)"><font-awesome-icon :icon="['fas', 'check']"/></v-btn>
                                    </v-list-item-action>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.text }}
                                            <template v-if="item.value !== item.text">
                                                ({{ item.value }})
                                            </template>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="purple" @click="editSelectChoice(idx)"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></v-btn>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </v-list>
                    </template>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="saveItem" :disabled="!isEditItemFormComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editInputDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editFormConfirmationDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Edit the confirmation page</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The confirmation page will be presented to your users when they successfully
                            complete the form.
                        </p>
                        <v-text-field v-model="editableFormConfirmationHeadline" label="Confirmation headline" hint="Try 'Thank You'"></v-text-field>
                        <v-textarea v-model="editableFormConfirmationMessage" label="Confirmation message" hint="This is optional"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="saveEditFormConfirmation" :disabled="!isEditFormConfirmationComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editFormConfirmationDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editFormStatusDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Form status</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5" v-if="form">
                    <p v-if="form.invite_id">
                        The form is currently published.
                    </p>
                    <p v-if="invite && invite.link">
                        <a :href="invite.link">Link to form</a>
                    </p>
                    <p v-if="!form.invite_id">
                        The form is currently a draft.
                    </p>
                <!-- <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <v-select v-model="editableItemType" label="Type" :items="formItemTypeChoices"></v-select>
                        <template v-if="editableItemType === 'input'">
                            <v-text-field v-model="editableInputLabel" label="Label"></v-text-field>
                            <v-text-field v-model="editableInputKey" label="Key"></v-text-field>
                        </template>
                    </v-col>
                    </v-row>
                </v-form> -->
                </v-card-text>
                <v-card-actions v-if="form">
                    <v-spacer></v-spacer>
                    <template v-if="form.invite_id">
                    <v-btn elevation="4" class="red white--text" @click="unpublishForm">
                        <span>Unpublish</span>
                    </v-btn>
                    </template>
                    <template v-if="!form.invite_id">
                    <v-btn elevation="4" class="purple white--text" @click="publishForm">
                        <span>Publish</span>
                    </v-btn>
                    </template>
                    <v-btn text color="grey" @click="editFormStatusDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style scoped>
@media print {
    .no-print {
        display: none;
    }
}
</style>

<script>
import { mapState } from 'vuex';
// import FormList from '@/components/account-dashboard/FormList.vue';
// import { compact } from '@/sdk/input';
import { randomText } from '@/sdk/random';

export default {
    components: {
        // FormList,
    },
    data: () => ({
        error: null,
        account: null,
        form: null,
        invite: null,
        // edit form title dialog
        editFormTitleDialog: false,
        editableFormTitle: null,
        createNewItemDialog: false,
        editableItemType: null, // 'input', 'separator', 'pagebreak'
        formItemTypeChoices: [
            { value: 'input', text: 'Data entry' },
            { value: 'hr', text: 'Horizontal separator' },
            { value: 'break', text: 'Page break' },
        ],
        editableInputType: null,
        formInputTypeChoices: [
            { value: 'select', text: 'Select from choices' }, // single-select or multi-select (array) from choices; render options include select box, radio (for single), checkbox (for single or multiple)
            { value: 'text', text: 'Text entry' }, // one-line or multi-line text, optional max # of characters, optional format specifier (e.g. six characters, six digits, etc.)
            { value: 'email', text: 'Email address' }, // result is text, input validation requires email address format
            { value: 'url', text: 'URL' }, // result is text, input validation requires URL format; optional scheme restriction to http, https, ftp, ftps, ssh, etc.
            // { value: 'uri', text: 'URI' }, // result is text, input validation requires URI format; optional scheme restriction to http, https, ftp, ftps, ssh, isbn, etc.
            { value: 'numeric', text: 'Numeric' }, // result is a number, optional restriction to integer, decimal, range (non-negatives, or 1970-2100, etc.)
            { value: 'currency', text: 'Currency' }, // result is number and currency units; option to limit currency to specific target units e.g. dollar, euro,  or to let user specify their currency unit; numeric result will actually be text with 'xxxx.yy'
            { value: 'date', text: 'Date' }, // result is yyyy-mm-dd, input can be date selector, and there can be a restriction on the allowed range
            { value: 'year', text: 'Year' }, // result is yyyy; input can be date selector with year-only, or single-select with years, or text input with validation
            { value: 'month', text: 'Month' }, // result is mm; input can be date selector with month-only, or single-select with months, or text input with validation
            { value: 'weekday', text: 'Weekday' }, // result is 1-7  with 1 = Sunday, 7 = Saturday; input can be single-select with weekdays, or text input with validation
            { value: 'time', text: 'Time' }, // result is hh:mm, input can be 12-hour or 24-hour
            { value: 'color', text: 'Color' }, // result is single value in hex format like #rrggbb; can display a color wheel, color square, or multiple choice from preselected colors
            { value: 'file', text: 'File upload' }, // we can allow user to restrict document types to some well-known types like pdf, docx, xlsx, pptx, odt, ods, odp, zip, tgz/.tar.gz, or allow any type of document
            { value: 'image', text: 'Image upload' }, // and we can have checkboxes for allowed image type like png, jpg, gif
        ],
        editableInputPrompt: null,
        editableInputNotice: null,
        editableInputLabel: null,
        editableInputHelptext: null,
        // editableInputKey: null,
        editableInputIsArray: false,
        editableInputIsRequired: false,
        editInputDialog: false,
        editableItemIndex: null,
        // 'select' attributes
        editableInputSelectList: [],
        editableInputSelectListEditIndex: null,
        editableInputSelectListEditLabel: null, // the display text for the choice
        editableInputSelectListEditCustomValue: false, // true if the stored value will differ from the text, false if they should be the same
        editableInputSelectListEditValue: null, // the store text for the choice (default same as display text)
        // form confirmation
        editFormConfirmationDialog: false,
        editableFormConfirmationHeadline: null,
        editableFormConfirmationMessage: null,
        // form status
        editFormStatusDialog: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        // isViewReady() {
        //     return this.account !== null && this.form !== null;
        // },
        typeDisplay() {
            switch (this.form?.type) {
            case 'f':
                return 'Form';
            case 'p':
                return 'Poll';
            case 's':
                return 'Survey';
            default:
                return `Unknown[${JSON.stringify(this.form?.type)}]`;
            }
        },
        isPublished() {
            return this.form?.invite_id;
        },
        statusDisplay() {
            if (this.isPublished) {
                return 'Published';
            }
            return 'Draft';
        },
        // allocated() {
        //     if (typeof this.form?.allocated_gb === 'number') {
        //         return `${this.form.allocated_gb} GB`;
        //     }
        //     return 'Unknown';
        // },
        // usage() {
        //     if (typeof this.form?.usage === 'number') {
        //         return humanReadableByteCountSI(this.form.usage);
        //     }
        //     return 'Unknown';
        // },
        isEditFormTitleComplete() {
            return typeof this.editableFormTitle === 'string' && this.editableFormTitle.trim().length > 0;
        },
        isNewItemFormComplete() {
            if (['hr', 'break'].includes(this.editableItemType)) {
                return true;
            }
            return typeof this.editableInputLabel === 'string'
            && this.editableInputLabel.trim().length > 0;
            // && this.editableInputType !== null
            // && ['select', 'text', 'email', 'url', 'uri', 'numeric', 'currency', 'date', 'year', 'month', 'weekday', 'time', 'color', 'file', 'image'].includes(this.editableInputType)
            // && typeof this.editableInputKey === 'string'
            // && this.editableInputKey.trim().length > 0;
        },
        isEditItemFormComplete() {
            return true; // TODO: essential/required fields + item type specific fields
        },
        isEditFormConfirmationComplete() {
            return typeof this.editableFormConfirmationHeadline === 'string'
            && this.editableFormConfirmationHeadline.trim().length > 0;
            // The message is currently optional.
            // && this.editableFormConfirmationMessage !== null
            // && this.editableFormConfirmationMessage.trim().length > 0;
        },
    },
    watch: {
        /*
        editableInputLabel(newValue) {
            // only set the input key automatically the first time; after that, it should stay the same so user doesn't end up with many columns representing the same input just because they changed the label
            // if (typeof this.editableInputKey !== 'string' || this.editableInputKey.length === 0) {
            //     this.editableInputKey = compact(newValue);
            // }
        },
        */
        editableInputSelectListEditCustomValue(newValue, oldValue) {
            if (oldValue && !newValue) {
                // property changed from 'custom value' to 'not custom value' so set the value equal to the label
                this.editableInputSelectListEditValue = this.editableInputSelectListEditLabel;
            }
        },
        editableInputSelectListEditLabel(newValue) {
            if (!this.editableInputSelectListEditCustomValue) {
                // choice value is not custom, so change it to equal the label
                this.editableInputSelectListEditValue = newValue;
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadForm() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadForm: true });
                const response = await this.$client.account(this.$route.params.accountId).form.get(this.$route.params.formId);
                console.log(`loadForm: response ${JSON.stringify(response)}`);
                if (response) {
                    this.form = response;
                    this.form.title ??= '';
                    this.form.input ??= {};
                    this.form.sequence ??= [];
                    this.form.confirm ??= {};
                    // if (this.form.website_id) {
                    //     this.loadWebsite();
                    // }
                    if (response.invite_id) {
                        this.loadInvite();
                    }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load form');
                }
            } catch (err) {
                console.error('failed to load form', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadForm: false });
            }
        },
        async loadInvite() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadInvite: true });
                const response = await this.$client.account(this.$route.params.accountId).invite.get(this.form.invite_id);
                console.log(`loadInvite: response ${JSON.stringify(response)}`);
                if (response) {
                    this.invite = response;
                } else {
                    console.error('failed to load invite');
                    this.$set(this.form, 'invite_id', null);
                }
            } catch (err) {
                console.error('failed to load invite', err);
                this.$set(this.form, 'invite_id', null);
            } finally {
                this.$store.commit('loading', { loadInvite: false });
            }
        },
        // async loadWebsite() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(this.form.website_id);
        //         console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //         } else {
        //             // TODO: redirect back to account list? show a not found message?
        //             console.error('failed to load website');
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        // async checkFormUsage() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { checkFormUsage: true });
        //         const response = await this.$client.account(this.$route.params.accountId).form.check(this.$route.params.formId, { item: 'usage' });
        //         console.log(`checkFormUsage: response ${JSON.stringify(response)}`);
        //         if (response?.form) {
        //             this.form = response.form;
        //         }
        //         // if (response?.isEdited) {
        //         //     this.$bus.$emit('snackbar', { type: 'error', headline: 'OK' });
        //         // } else {
        //         //     this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check form' });
        //         // }
        //     } catch (err) {
        //         console.error('failed to check form', err);
        //         this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check form usage' });
        //     } finally {
        //         this.$store.commit('loading', { checkFormUsage: false });
        //     }
        // },
        addInput() {
            this.editableItemType = null;
            this.editableInputPrompt = null;
            this.editableInputNotice = null;
            this.editableInputLabel = null;
            this.editableInputHelptext = null;
            // this.editableInputKey = null;
            this.editableInputIsArray = false;
            this.editableInputIsRequired = false;
            // 'select' attributes
            this.editableInputSelectList = [];
            this.createNewItemDialog = true;
        },
        async save(formAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditForm: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).form.edit({ id: this.$route.params.formId }, formAttr);
                console.log(`saveEditForm: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit form' });
                return false;
            } catch (err) {
                console.error('failed to edit form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit form' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditForm: false });
            }
        },
        editFormTitle() {
            this.editFormTitleDialog = true;
            this.editableFormTitle = this.form.title;
        },
        async saveEditFormTitle() {
            const isEdited = await this.save({ title: this.editableFormTitle });
            this.editFormTitleDialog = false;
            if (isEdited) {
                this.$set(this.form, 'title', this.editableFormTitle);
            }
        },
        async createItem() {
            // add input ot the json and automatically save what we have to server
            // TODO: add etag so if multiple users editing the same form, they don't overwrite each otehrs changes ... if etag clonflcit, then load current(updated) form ,then add the input, and save.
            try {
                const type = this.editableItemType;
                if (type === 'input') {
                    // generate a unique key
                    const charset = '0123456789abcdefghjkmnpqrstvwxyz'; // Crockford's base32 character set
                    let size = 4;
                    let key = randomText(size, charset);
                    console.log(`createItem generated key ${key} size ${size}`);
                    while (typeof this.form.input[key] !== 'undefined') {
                        size += 1;
                        key = randomText(size, charset);
                        console.log(`createItem generated key ${key} size ${size}`);
                    }
                    const input = {
                        prompt: this.editableInputPrompt,
                        notice: this.editableInputNotice,
                        label: this.editableInputLabel,
                        help: this.editableInputHelptext,
                        type: this.editableInputType,
                        is_array: false,
                        is_required: false,
                        // TODO: type-specific attributes
                    };
                    this.form.input[key] = input;
                    this.form.sequence.push({ type: 'input', key }); // other things that can be in the sequence are separators, page breaks, videos, etc.
                } else if (type === 'hr') {
                    this.form.sequence.push({ type: 'hr' });
                } else if (type === 'break') {
                    this.form.sequence.push({ type: 'break' });
                } else {
                    console.error(`createItem: unknown item type ${type}`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Unknown item type' });
                    return;
                }
                const saved = await this.save({ input: this.form.input, sequence: this.form.sequence });
                console.log(`createItem saved? ${saved}`);
                if (saved && type === 'input') {
                    this.$nextTick(() => this.editItem(this.form.sequence.length - 1));
                }
            } catch (err) {
                console.error('failed to edit form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit form' });
            } finally {
                this.$store.commit('loading', { createItem: false });
                this.createNewItemDialog = false;
            }
        },
        async editItem(idx) {
            if (this.form.sequence.length <= idx) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit item' });
                return;
            }
            this.editableItemIndex = idx;
            // TODO: set all the editable fields using the selected item  this.form.sequence[idx]
            const item = this.form.sequence[idx];
            if (item.type === 'input') {
                // this.editableInputKey = item.key; // TODO: keys cannot be renamed casually... it requires a call to server to rename existing data, so should be in a separate "rename key" form
                const input = this.form.input[item.key];
                this.editableInputPrompt = input.prompt;
                this.editableInputNotice = input.notice;
                this.editableInputLabel = input.label;
                this.editableInputHelptext = input.helptext;
                this.editableInputType = input.type;
                this.editableInputIsArray = input.is_array;
                this.editableInputIsRequired = input.is_required;
                // TODO: type-specific attributes
                if (input.type === 'select') {
                    this.editableInputSelectList = input.select?.choices ?? [];
                }
            }
            // TODO: else -- separator, page break, video, etc.
            this.editInputDialog = true;
        },
        async saveItem({ closeOnSave = true } = {}) {
            // TODO: check if any changes were made since the last save; if no changes, just close the dialog without sending anything to server.
            try {
                this.$store.commit('loading', { saveItem: true });
                const item = this.form.sequence[this.editableItemIndex];
                if (item.type === 'input') {
                    const input = this.form.input[item.key];
                    input.prompt = this.editableInputPrompt;
                    input.notice = this.editableInputNotice;
                    input.label = this.editableInputLabel;
                    input.helptext = this.editableInputHelptext;
                    input.type = this.editableInputType;
                    input.is_array = this.editableInputIsArray;
                    input.is_required = this.editableInputIsRequired;
                    // TODO: type-specific attributes
                    if (input.type === 'select') {
                        input.select ??= {};
                        input.select.choices = this.editableInputSelectList ?? [];
                    }
                    this.$set(this.form.input, item.key, input);
                }
                await this.save({ input: this.form.input });
            } catch (err) {
                console.error('failed to save form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save form' });
            } finally {
                this.$store.commit('loading', { saveItem: false });
                if (closeOnSave) {
                    this.editInputDialog = false;
                }
            }
        },
        async deleteItem(idx) {
            // TODO: if we already have inputs for the form, we can't delete the item or else we won't know what's in the data... so user can only delete fields that are not in the data, OR there should be a big warning that the data will be deleted from all past inputs and give a number of records that have this input filled in; THE OTEHR OPTION is that we only remove existing fields from the SEQUENCE but we keep them in the input mapping, so they won't be shown to new users filling out the form but we still know what old data was... and we can display "removed" fields separately from the sequence, and user could even put them back.
            if (this.form.sequence.length <= idx) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete item' });
                return;
            }
            try {
                const removed = this.form.sequence.splice(idx, 1);
                console.log(`deleteItem: removed ${JSON.stringify(removed)}`);
                this.error = false;
                this.$store.commit('loading', { deleteItem: true });
                const saved = await this.save({ sequence: this.form.sequence });
                console.log(`deleteItem: saved? ${saved}`);
            } catch (err) {
                console.error('failed to edit form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit form' });
            } finally {
                this.$store.commit('loading', { deleteItem: false });
            }
        },
        addSelectChoice() {
            this.editableInputSelectList ??= [];
            this.editableInputSelectList.push({ text: null, value: null });
            this.editSelectChoice(this.editableInputSelectList.length - 1);
        },
        editSelectChoice(idx) {
            this.editableInputSelectListEditIndex = idx;
            this.editableInputSelectListEditLabel = this.editableInputSelectList[idx].text;
            this.editableInputSelectListEditValue = this.editableInputSelectList[idx].value;
        },
        async saveSelectChoice(idx) {
            this.editableInputSelectList.splice(idx, 1, { text: this.editableInputSelectListEditLabel, value: this.editableInputSelectListEditValue });
            this.editableInputSelectListEditIndex = null;
            await this.saveItem({ closeOnSave: false });
            // const key = this.editableInputKey;
            // const input = this.form.input[key];
            // input.select ??= {};
            // input.select.choices = this.editableInputSelectList;
            // this.$set(this.form.input, key, input);
            // try {
            //     this.$store.commit('loading', { saveSelectChoice: true });
            //     const saved = await this.save();
            //     console.log(`saveSelectChoice: saved? ${saved}`);
            // } catch (err) {
            //     console.error('failed to edit form', err);
            //     this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit form' });
            // } finally {
            //     this.$store.commit('loading', { saveSelectChoice: false });
            // }
        },
        editFormConfirmation() {
            this.editFormConfirmationDialog = true;
            this.editableFormConfirmationHeadline = this.form.confirm?.headline;
            this.editableFormConfirmationMessage = this.form.confirm?.message;
        },
        async saveEditFormConfirmation() {
            const confirm = {
                headline: this.editableFormConfirmationHeadline,
                message: this.editableFormConfirmationMessage,
            };
            const isEdited = await this.save({ confirm });
            this.editFormConfirmationDialog = false;
            if (isEdited) {
                this.$set(this.form, 'confirm', confirm);
            }
        },
        editFormStatus() {
            this.editFormStatusDialog = true;
        },
        async publishForm() {
            try {
                this.error = false;
                this.$store.commit('loading', { publishForm: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).invite.create({ type: 'form-entry', form_id: this.$route.params.formId });
                console.log(`publishForm: response ${JSON.stringify(response)}`);
                if (response?.isCreated) {
                    this.$set(this.form, 'invite_id', response.id);
                    this.loadInvite();
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Published' });
                    return;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish form' });
            } catch (err) {
                console.error('failed to edit form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to publish form' });
            } finally {
                this.$store.commit('loading', { publishForm: false });
                this.editFormStatusDialog = false;
            }
        },
        async unpublishForm() {
            try {
                this.error = false;
                this.$store.commit('loading', { unpublishForm: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).invite.delete({ id: this.form.invite_id });
                console.log(`unpublishForm: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    this.$set(this.form, 'invite_id', null);
                    this.invite = null;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Unpublished' });
                    return;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish form' });
            } catch (err) {
                console.error('failed to edit form', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to unpublish form' });
            } finally {
                this.$store.commit('loading', { unpublishForm: false });
                this.editFormStatusDialog = false;
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadForm();
    },
};
</script>
